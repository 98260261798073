import React from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import { isAndAbove } from '../util';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const NewsPostTemplate = props => {
  const post = get(props, 'data.contentfulNews');

  const breakpoints = useBreakpoint();

  const paddingTop = isAndAbove('md', breakpoints) ? 86 : 66;

  const contentJson = get(post, 'content.json');
  const contentRenderOptions = {
    renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        console.log(children);
        return <p className="my-6 text-lg leading-relaxed">{children}</p>;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        const href = node.data.uri;
        return href.includes('http') ? (
          <a className="text-blue" href={href} target="_blank">
            {children}
          </a>
        ) : (
          <Link className="text-blue" to={href}>
            {children}
          </Link>
        );
      }
    }
  };

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title={post.title} />
      <div className="bg-gray-900" style={{ paddingTop: paddingTop }}>
        <div className="bg-gray-100">
          <div className="max-w-4xl mx-auto px-6 py-12 sm:py-16">
            <div className="text-center">
              <div className="mb-2 text-lg text-gray-500">{post.publishedDate}</div>
              <div className="mb-4 sm:mb-12 text-3xl sm:text-4xl leading-10 font-extrabold text-blue-darker">
                {post.title}
              </div>
            </div>
            <img
              className="max-h-96 w-full object-cover overflow-hidden rounded-xl"
              src={post.image.file.url}
              alt={post.image.title}
            />
            <div className="my-12 max-w-4xl mx-auto">
              {documentToReactComponents(contentJson, contentRenderOptions)}
            </div>
          </div>
        </div>
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

export default NewsPostTemplate;

export const pageQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    contentfulNews(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "MMMM Do, YYYY")
      image {
        title
        file {
          url
        }
      }
      content {
        json
      }
    }
  }
`;
